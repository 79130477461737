function Whatsapp (){

    return(     
        <div id="whatsapp-button" className="fixed-bottom z-n1 d-flex justify-content-end pb-2 pe-4 z-3">
        <a 
            href="https://wa.me/+32472733073"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src="./img/icon-whatsapp.png" alt="WhatsApp" style={{ width: '40px', height: '40px' }} />
        </a>
        </div>
    )
}
export default Whatsapp;