import React from 'react';
import emailjs from 'emailjs-com';

function Contact (){

    const sendInfo = () => {
        const name = document.getElementById('name').value;
        const mail = document.getElementById('mail').value;
        const objet = document.getElementById('object').value;
        const message = document.getElementById('message').value;
    
        if (name && mail && objet && message) {
            const btnContact = document.getElementById("btnContact");
            const spinner = document.getElementById("spinner");
            const btnText = document.getElementById("button-text")
            btnContact.disabled = true;
            spinner.classList.remove("d-none");
            btnText.textContent = "Envoi...";
    
            emailjs.send('service_w582vgj', 'template_gpqoe1s', {
                name: name,
                email: mail,
                subject: objet,
                message: message
            }, 'WTd3-5oqOSkTRtGhK')
            .then((response) => {
                spinner.classList.add("d-none");
                btnText.textContent = "C'est envoyé ! ;)";
            }, (err) => {
                alert("Une erreur est survenue. Veuillez réessayer.");
                spinner.classList.add("d-none");
                btnText.textContent = "Envoyer";
                btnContact.disabled = false;
            });
        }
        else{
            alert("Il manque certaines informations. Completez le formulaire afin que je puisse vous répondre au mieux !")
        }
 
      }

    return (
        <div class="vh-100 mt-5 pt-5 container-fluid d-flex align-items-center overflow-y-hidden">
            <form class="row w-100 px-1">
                <div className="col-lg-6 my-2">
                    <label className="fs-5" for="name">Name</label>
                    <input id="name" className="form-control-lg d-block w-100 border-1 " type="text" required />
                </div>
                <div className="col-lg-6 my-2">
                    <label className="fs-5" for="mail">e-Mail</label>                    
                    <input id="mail" className="form-control-lg d-block w-100 border-1 " type="mail" required />
                </div>
                <div className="col-12 my-2">
                    <label className="fs-5" for="object">Object</label>
                    <input id="object" className="form-control-lg d-block w-100 border-1 " type="text" required />
                </div>
                <div className="col-12 my-2">
                    <label className="fs-5" for="message">Your message</label>
                    <textarea id="message" className="form-control-lg d-block w-100 border-1 " rows="7" required />
                </div>
                <div className="col-3 my-3">
                    <div onClick={() => sendInfo()} id="btnContact" type="button" class="btn btn-dark m-0 d-inline-flex align-items-center">
                        <span id="spinner" className="spinner-border text-light d-none me-3" aria-hidden="true"></span>
                        <span id="button-text" role="status" className="fs-6 py-1  text-white">Envoyer</span>
                    </div>          
                </div>
            </form>
        </div>
    )
} 
export default Contact;