import React, { useEffect, useRef, useState } from 'react';

function Home() {
  const [order, setOrder] = useState([]);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const sectionModulable = useRef(null);

  useEffect(() => {
    fetch('https://aude-dewolfbelgium.com/api/api/content/item/order')
    .then(response => response.json())
    .then(data => {
      setOrder(data.order.split(',').map(str => parseInt(str, 10)));
    })
    .catch(error => console.error('Erreur lors de la récupération des données :', error));

    fetch('https://aude-dewolfbelgium.com/api/api/content/items/images')
      .then(response => response.json())
      .then(data => {
        setImages(data.reverse());
      })
      .catch(error => console.error('Erreur lors de la récupération des données :', error));

      fetch('https://aude-dewolfbelgium.com/api/api/content/items/textover')
      .then(response => response.json())
      .then(data => {
        setTexts(data);
      })
      .catch(error => console.error('Erreur lors de la récupération des données :', error));

      const handleScroll = () => {
        if (window.scrollY === 0) {
          setSelectedCollection(null);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

  }, []);

  function GoToStart () {
    if (sectionModulable.current) {
      sectionModulable.current.scrollIntoView();
    }
  }

  const handleImageClick = (serie) => {
    setSelectedCollection(serie);
    GoToStart();
  };

  const sortedImages = [...images].sort((a, b) => {
    // On récupère l'index de la série dans 'order', ou -1 si elle n'est pas trouvée
    const indexA = order.indexOf(a.serie);
    const indexB = order.indexOf(b.serie);
    
    // Si une série n'est pas trouvée, on place son image en fin de liste
    const rankA = indexA === -1 ? Infinity : indexA;
    const rankB = indexB === -1 ? Infinity : indexB;
  
    return rankA - rankB;
  });
  

  const displayedImages = selectedCollection 
  ? sortedImages.filter((image) => image.serie === selectedCollection) 
  : sortedImages
      .filter((image) => image.favorite)
      .reduce((acc, curr, index) => {
        acc.push({ ...curr, type: 'image' });

        if ((index + 1) % 2 === 0 && texts[Math.floor(index / 2)]) {
          acc.push({ ...texts[Math.floor(index / 2)], type: 'text' });
        }

        return acc;
      }, []);


  return (
    <div id="Home" className="container-fluid pt-5 mt-5">
      <main className="d-flex align-items-center pt-5">
        <div>
          <h1 className="fs-4 mb-2 LBB text-uppercase">Aude De Wolf Belgium</h1>
          <h2 className="mb-5 fs-4 LBR">
            Handmade & tailored clothing <br />
            Made in Brussels
          </h2>
          <h3 className="text-uppercase fs-6 LBR">
            The Shop is Open on <br/>
            Tuesday to Saturday <br class="noPC" /> <span class="noMobile">:</span> <span class="noPC">From </span> 11am – 6.30pm <br />
            at 99, rue de l’Aqueduc - <span class="noMobile">1050</span> Ixelles
          </h3>
        </div>
      </main>

      <section ref={sectionModulable} class="position-relative pt-5">

        <div className="row justify-content-between position-relative">
          {displayedImages.length > 0 && displayedImages.map((item, index) => {
            let taille = 6;
            let dispo = ""; 
            let orientation = "start"

            if(selectedCollection !== null || (item.taille === null || !item.taille) )
            {
              switch (index % 4) {
                case 0:
                case 3:
                  taille = 6;
                  break;
                case 1:
                case 2:
                  taille = 5;
                  break;
                default:
                  taille = 6;
              }
        
              let orientation = Math.floor(Math.random() * 3) + 1;
              switch (orientation) {
                case 1:
                  orientation = 'start';
                  break;
                case 2:
                  orientation = 'end';
                  break;
                case 3:
                  orientation = 'center';
                  break;
                default:
                  orientation = 'center';
                  break;
              }
            }
            else{taille = item.taille}

            if (selectedCollection !== null)
            {
              dispo = "";
            }
            else{dispo = item.class}
            

            if((selectedCollection === null && item.type === 'image') || selectedCollection !== null)
            {
            return (
              <div
                key={index}
                className={`py-3 px-2 py-md-4 px-md-3 z-2 d-flex col-${taille} align-items-${orientation} ${dispo}`}
                onClick={() => handleImageClick(item.serie)}
              >
                <div class="zoom-effect">
                  <img src={"https://aude-dewolfbelgium.com/api/storage/uploads"+item.url.path} className={"img-fluid z-2 light " + item.serie} alt={item.description} />
                </div>
              </div>
            );
            }

            if (selectedCollection === null && item.type === 'text') {
              const cote = index % 2 === 0 ? "start" : "end";
              
              // Split the text to isolate the first word
              const words = item.text.split(" ");
              const firstWord = words[0]; // The first word
              const restOfText = words.slice(1).join(" "); // The rest of the text
            
              return (
                <div key={index} className={"container-fluid z-3 no-click d-flex align-items-center m-0 justify-content-" + cote + " " + item.class}>
                  <p className="fs-8 z-3 w-75 my-0 my-md-2">
                    <strong>{firstWord}</strong> {restOfText}
                  </p>
                </div>
              );
            }
            else{return null}
            
          })}
        </div>

        {selectedCollection !== null && 
        <div id="clearButton" onClick={() => handleImageClick(null)} class="d-inline-flex justify-content-start align-items-center bg-white z-4 pt-2 pb-3">
            <img class="me-2" src="./img/icon-fleche.png" alt="fleche pour retourner à la page précédente" style={{ width: '18px'}} />
            <span class="fs-6 p-0">Return</span>
        </div>}

      </section>

    </div>
  );
}

export default Home;