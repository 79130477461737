import React from 'react';
import { Link } from 'react-router-dom';
import MailChimp from './MailChimp';
import Whatsapp from './Whatsapp';


function Footer ({ScrollToTop}){
    return (
        <div>
        <Whatsapp />
        <footer class="container-fluid border-top bg-white position-relative z-3">
            <div className="row fs-8">
                <div className="col-md-4 py-3 text-center text-md-start order-last order-md-1">
                    <p><a href="tel:+32472733073">+32 (0) 472 73 30 73</a></p>
                    <p><a href="mailto:info@aude-dewolfbelgium.com">info@aude-dewolfbelgium.com</a></p>
                </div>
                <div className="col-md-4 py-3 text-center text-md-start order-2">
                    <MailChimp />
                </div>
                <div className="col-md-4 py-3 text-center text-md-end order-first order-md-3">
                    <p class="m-0">
                        <a class="me-2" href="https://www.facebook.com/audedewolfbelgium/" target="_blank" rel="noopener noreferrer"><img src="./img/icon-facebook.png" alt="Lien vers Facebook" style={{ width: '30px'}} /></a>
                        <a class="mx-2" href="https://www.instagram.com/audedewolfbelgium/" target="_blank" rel="noopener noreferrer"><img src="./img/icon-instagram.png" alt="Lien vers Instagram" style={{ width: '30px'}} /></a>
                        <a class="ms-1" href="https://wa.me/+32472733073" target="_blank" rel="noopener noreferrer"><img src="./img/icon-whatsapp.png" alt="Lien vers Whatsapp" style={{ width: '35px'}} /></a>
                    </p>
                </div>
                {/*<div className="col-md-4 py-3">
                    <p class="m-0">Aude De Wolf Belgium</p>
                    <p class="m-0">Siège social et d’exploitation :</p>
                    <p class="m-0">N° de TVA : BE 0759 434 576</p>
                    <p class="m-0">1, avenue Neptune boîte 34, 1190 Forest</p>
                </div>*/}
            </div>

            <div class="pt-4 pb-5 fs-8">
                <span class="d-block text-center">©{new Date().getFullYear()} Aude De Wolf Belgium</span>
                <sub><Link to="legales" class="d-block text-center mt-2" onClick={ScrollToTop}>Mentions Légales</Link></sub>
            </div>
        </footer>
    </div>
    )
} 
export default Footer;