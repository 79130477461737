import React, { useEffect, useState } from 'react';

function Store (){
    const [image, setImage] = useState({});
    const [guests, setGuests] = useState([]);

    useEffect(() => {
        fetch('https://aude-dewolfbelgium.com/api/api/content/item/store')
          .then(response => response.json())
          .then(data => {
            setImage(data.url.path);
          })
          .catch(error => console.error('Erreur lors de la récupération des données :', error));
    
          fetch('https://aude-dewolfbelgium.com/api/api/content/items/guests')
          .then(response => response.json())
          .then(data => {
            setGuests(data);
          })
          .catch(error => console.error('Erreur lors de la récupération des données :', error));
    
      }, []);
    return (
        <div class="container-fluid" style={{ marginTop: '56px'}}>
            <h1 class="LBR pt-5 pb-3 text-center">The Store</h1>
            <div className="row justify-content-center">
                <div className="col-md-8 col-12 py-4">
                    <img class="img-fluid" alt="Face avant du magasin Aude De Wolf au 99 Rue de L'acqueduc, Ixelles" src={"https://aude-dewolfbelgium.com/api/storage/uploads"+image} />
                </div>
                <div className="col-12 fs-5 py-4 d-flex flex-column align-items-center">
                    <h2 className="text-uppercase text-center fs-5 LBR mt-2">
                        Tuesday to Saturday : 11am – 6.30pm <br />
                        at 99, rue de l’Aqueduc - 1050 Ixelles
                    </h2>

                    <p className="text-uppercase text-decoration-underline fs-6 LBR mt-5 mb-4">Guests of this season :</p>
                    {guests.length > 0 && guests.map((guest, index) => {
                        return(
                            <p key={index} className="text-uppercase text-center fs-8 LBR">{guest.name}</p>
                        )
                    })}
                </div>
            </div>
        </div>
    )
} 
export default Store;