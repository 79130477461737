function Legales () {

    return(
        <div class="container-fluid mt-5 pt-5">
            <h1>Mentions légales et CGU</h1>

            <h2>Mentions légales</h2>
            <p>
                Les présentes conditions générales ont pour objet de définir les modalités et conditions d’utilisation, par tous visiteurs, du site www.thewolfbelgium.be, ci-après « le site »,
                appartenant à la société TheWolfBelgium qui travaille par l’intermédiaire de la coopérative
                d’activités BatiCrea – N° de TVA : BE 0535 451 255 – Siège social et d’exploitation : <br/>
                Rue d’Alost 7-11, 1000 Bruxelles – Email : info@jobyourself.be – Web : http://www.jobyourself.be ».
            </p>

            <p>
                Toute consultation/interaction avec ce site est subordonnée au respect des présentes conditions générales. Toute consultation/interaction entraîne l’adhésion pleine et entière aux présentes conditions.
            </p>

            <h2>Conditions Générales d’Utilisation</h2>
            <h3>Préambulle</h3>
            <p>Toute visite sur ce site entraîne l’acceptation des présentes conditions.</p>
            <p>The Wolf Belgium sera mentionné comme TWB dans ces conditions. L’internaute sera appelé visiteur.</p>

            <h3>Modifications du site</h3>
            <p>TWB peut, à tout moment et sans avertissement préalable, apporter des modifications aux informations et aux services présentés sur le site.</p>

            <h3>Protection de la vie privée</h3>
            <p>TWB applique les principes de la loi vie privée du 8 décembre 1992.</p>
            <p>Lorsqu’un visiteur consulte le site internet, il est possible que des données d’analyses (aussi appelées cookies) soient enregistrés de manière totalement anonyme et à des fins de statistiques et non commerciales uniquement. Ces données sont collectées à titre confidentiel et traitées au niveau interne.</p>

            <h3>Accès restreint à certaines parties du site</h3>
            <p>Certaines parties de ce site sont accessibles sur demande par e-mail. Tout accès par un autre biais est strictement prohibé.</p>

            <h3>Informations</h3>
            <p>Les informations fournies sur le site le sont à titre indicatif. TWB met en œuvre tous les moyens pour mettre à jour, modifier et corriger les informations disponibles sur le site le plus rapidement possible.</p>
        
            <h3>Droits d’auteur</h3>
            <p>L’intégralité du contenu du site www.thewolfbelgium.be, ainsi que sa conception, sont la propriété exclusive de TWB.</p>

            <h3>Loi applicable et compétence territoriale</h3>
            <p>Les présentes conditions sont régies par le droit belge. Seuls les tribunaux de l’arrondissement de Bruxelles, en langue française ou néerlandaise, sont compétents pour connaître d’éventuels litiges qui pourraient en découler.</p>

            <h3>Liens hypertextes et cookies</h3>
            <p>Le site http://www.thewolfbelgium.be contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de TWB. Cependant, TWB n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>
            <p>La navigation sur le site http://www.thewolfbelgium.be est susceptible de provoquer l’installation de cookies sur l’ordinateur de l’utilisateur.</p>

            <h3>Qu’est-ce qu’un cookie ?</h3>
            <p>Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site (préférence linguistique, par exemple), et ont également vocation à permettre diverses mesures de fréquentation.</p>
        
        </div>
    )

}
export default Legales;