import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Nav from './Components/Navigation'
import Footer from './Components/Footer'

import Home from './pages/Home'
import Store from './pages/Store';
import Contact from './pages/Contact'
import About from './pages/About'
import Legales from './pages/Legales'

function App() {
  function ScrollToTop() {
      window.scrollTo(0, 0);
    }
    
  return (
    <Router>
      <ScrollToTop />
      <Nav ScrollToTop = {ScrollToTop}  />

      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/store" element={<Store />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/legales" element={<Legales />} />
      </Routes>

      <Footer ScrollToTop = {ScrollToTop} />
    </Router>
  );
}

export default App;
