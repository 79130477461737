import React, { useEffect, useState } from 'react';

function About (){
    const [image, setImage] = useState({});
    useEffect(() => {
        fetch('https://aude-dewolfbelgium.com/api/api/content/item/about')
          .then(response => response.json())
          .then(data => {
            setImage(data.url.path);
          })
          .catch(error => console.error('Erreur lors de la récupération des données :', error));
    
      }, []);
    return (
        <div class="container-fluid" style={{ marginTop: '56px'}}>
            <h1 class="LBR pt-5 pb-4">About Aude De Wolf </h1>
            <div className="row">
                <div className="col-md-5 py-4">
                    <img class="img-fluid" src={"https://aude-dewolfbelgium.com/api/storage/uploads"+image} alt={"Aude De Wolf"} />
                </div>
                <div className="col-md-7 fs-5 py-4 ">
                    <p class="my-4 LBR">
                    Graduated in fashion design, Aude De Wolf opened her first shop in the Brussel's Dansaert district in 2003.
                    </p>
                    <p class="my-4 LBR">
                    After years working in the fashion field, she created her own label in 2018, and opened a new shop.
                    </p>
                    <p class="my-4 LBR">
                    Far from fast fashion references, the brand's purpose is based on quality, craftsmanship, timeless design.
                    </p>
                    <p class="my-4 LBR">
                    All the collections are handmade in Belgium.
                    </p>
                </div>
            </div>
        </div>
    )
} 
export default About;