function MailChimp(){
    return(
        <div id="mc_embed_shell">
            <div id="mc_embed_signup w-100">
                <form action="https://aude-dewolfbelgium.us7.list-manage.com/subscribe/post?u=8fdfe405ae28f1f6f5f68fb27&amp;id=30e17b5882&amp;f_id=0088b8e4f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="">
                    <div id="mc_embed_signup_scroll">
                        <div class="mc-field-group d-inline-block me-1">
                            <label for="mce-EMAIL" class="fs-8">Subscribe to the Newsletter</label>
                            <input type="email" name="EMAIL" class="required email form-control mb-2 w-100" id="mce-EMAIL" placeholder="e-Mail" required="" />
                        </div>

                        <div class="clear d-inline-block ms-1">
                            <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button btn btn-dark text-white" value="Subscribe" />
                        </div>

                        <div id="mce-responses" class="clear">
                            <div class="response d-none" id="mce-error-response"></div>
                            <div class="response d-none" id="mce-success-response"></div>
                        </div>

                        <div aria-hidden="true"  style={{position: "absolute", left: "-5000px"}}>
                            <input type="text" name="b_8fdfe405ae28f1f6f5f68fb27_30e17b5882" tabindex="-1" value=""/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default MailChimp;