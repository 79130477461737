import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Navigation({ScrollToTop}) {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClick = () => {
    setMenuVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav id="nav" className="container-fluid fixed-top z-4 d-flex justify-content-between align-items-center">
      <Link onClick={ScrollToTop} to="/" id="logo" class="bg-none">
        <img class="bg-none ms-1 py-3" src="./img/logo-transparent.png" alt="logo de Aude De Wolf Belgium" style={{width : "120px"}} />
      </Link>

      <div 
        id="menu" 
        className="d-block position-relative me-1" 
        onClick={handleMenuClick} 
        ref={menuRef}
      >
        <img src='./img/icon-burger.png' style={{width : "30px"}} alt="Accès au menu" />
        <div
          id="sous-menu"
          className={`position-absolute mt-3 text-center z-5 end-0 ${isMenuVisible ? '' : 'd-none'}`}
        >
          <Link className="d-block py-3 px-5" onClick={ScrollToTop} to="/">Home</Link>
          <Link className="d-block py-3 px-5" onClick={ScrollToTop} to="/store">Store</Link>
          <Link className="d-block py-3 px-5" onClick={ScrollToTop} to="/about">About</Link>
          <Link className="d-block py-3 px-5" onClick={ScrollToTop} to="/contact">Contact</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
